import { useState, useRef, forwardRef, useContext, useEffect } from "react";
import "./investisseur.scss";
import { StoreContext } from "../../store/store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/core";
import "swiper/swiper.min.css";

export const Investisseur = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { sliderHome = {}, content = {}, button = {} } = section;
  const [slidesPerView, setSlidesPerView] = useState(5);
  const { lang } = useContext(StoreContext);
  const [categoryOpen, setCategoryOpen] = useState({});

  const toggleItem = (index) => {
    setCategoryOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    const updateSlidesPerView = () => {
      if (window.innerWidth <= 500) {
        setSlidesPerView(1);
      } else if (window.innerWidth <= 750) {
        setSlidesPerView(2);
      } else if (window.innerWidth <= 1072) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(5);
      }
    };
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);
    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  function telechargerPDF(url) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Créez un objet URL à partir du blob
        const blobUrl = window.URL.createObjectURL(blob);

        // Créez un élément <a> pour le téléchargement du fichier
        const a = document.createElement("a");
        a.href = blobUrl;
        a.target = "_blank"; // Vous pouvez supprimer ceci si vous le souhaitez
        a.download = "nom-du-fichier.pdf"; // Vous pouvez personnaliser le nom du fichier téléchargé

        // Déclenchez un clic sur l'élément <a> pour démarrer le téléchargement
        a.click();

        // Libérez l'URL de l'objet blob après le téléchargement
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error(
          "Une erreur s'est produite lors du téléchargement du fichier :",
          error
        );
      });
  }

  function RedirectPDf(url) {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";

    a.click();
  }

  console.log(section.ListCategoryInvest.data);
  return (
    <section className={`section-Investisseur`}>
      <div className={`section-Investisseur-header`}>
        <div className={`section-Investisseur-header-midd`}>
          <h2>{section.title.data}</h2>
        </div>
      </div>

      {section.ListCategoryInvest.data.map((menuItem, index) => (
        <>
          <div className={`section-Investisseur-title`} key={index}>
            <div className={`section-Investisseur-header-midd`}>
              <h3 onClick={() => toggleItem(index)}>
                {menuItem[lang].title.data}
              </h3>
              <p onClick={() => toggleItem(index)}>
                {categoryOpen[index] ? "-" : "+"}
              </p>
            </div>
          </div>

          <div
            spaceBetween={0}
            slidesPerView={slidesPerView}
            className={`section-Investisseur-spawn ${
              categoryOpen[index] && "active"
            }`}
          >
            {menuItem[lang].sousCategoryInvest?.data.map(
              (subItem, subIndex) => (
                <div
                  key={subIndex}
                  className={`section-Investisseur-spawn-element`}
                >
                  <p>{subItem[lang].date.data}</p>
                  <p>{subItem[lang].title.data}</p>
                  <div className={`section-Investisseur-spawn-element-icons`}>
                    {(subItem[lang] && subItem[lang].media.data?.path) && (
                      <>
                        <span
                          className="material-symbols-outlined"
                          onClick={() =>
                            RedirectPDf(subItem[lang].media.data.path)
                          }
                        >
                          arrow_forward_ios
                        </span>

                        <span
                          className="material-symbols-outlined"
                          onClick={() =>
                            telechargerPDF(subItem[lang].media.data.path)
                          }
                        >
                          download
                        </span>
                      
                      </>
                    )}
                      <a style={{ display: "none" }}>Télécharger</a>
                  </div>
                </div>
              )
            )}
          </div>
        </>
      ))}

      <div className={`section-Investisseur-header`}>
        <div className={`section-Investisseur-header-midd`}></div>
      </div>
    </section>
  );
});

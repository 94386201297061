import { useState, useRef, forwardRef, useContext, useEffect } from "react";
import "./ourActivities.scss";
import { StoreContext } from "../../store/store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/core";
import "swiper/swiper.min.css";
import { Link } from "react-router-dom";

export const OurActivities = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { sliderHome = {}, content = {}, button = {} } = section;
  const [slidesPerView, setSlidesPerView] = useState(5);
  const [phone, setPhone] = useState(false);

  const { lang } = useContext(StoreContext);
  const [categoryOpen, setCategoryOpen] = useState({});

  const toggleItem = (index) => {
    setCategoryOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleSlideClick = (link) => {
    window.location.href = `/${lang}/${link}`;
  };

  useEffect(() => {
    const updateSlidesPerView = () => {
      if (window.innerWidth <= 500) {
        setSlidesPerView(5);
        setPhone(true);
      } else if (window.innerWidth <= 750) {
        setSlidesPerView(5);
        setPhone(true);
      } else if (window.innerWidth <= 1072) {
        setSlidesPerView(3);
        setPhone(false);
      } else {
        setSlidesPerView(5);
        setPhone(false);
      }
    };
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);
    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  const LinkTo = (link) => {
    window.location.href = `/${lang}/${link}`;
  };

  return (
    <section className={`section-OurActivities`}>
      <div className={`section-OurActivities-header`}>
        <div className={`section-OurActivities-header-midd`}>
          <h2>{section.title.data}</h2>
        </div>
      </div>

      {section.category.data.map((menuItem, index) => (
        <>
          <div className={`section-OurActivities-title`} key={index}>
            <div className={`section-OurActivities-header-midd`}>
              <h3
                onClick={() => {
                  if (index == 0) {
                    toggleItem(index);
                  }
                }}
              >
                {menuItem[lang].title.data}
              </h3>
              {index != 0 ? (
                <p onClick={() => LinkTo(menuItem[lang].title.slug)}>+</p>
              ) : (
                <p onClick={() => toggleItem(index)}>
                  {categoryOpen[index] ? "-" : "+"}
                </p>
              )}
            </div>
          </div>

          <Swiper
            spaceBetween={0}
            slidesPerView={slidesPerView}
            className={`section-OurActivities-spawn ${
              categoryOpen[index] && "active"
            }`}
            direction={phone ? "vertical" : "horizontal"}
          >
            {menuItem[lang].sousCategorie?.data.map((subItem, subIndex) => (
              <SwiperSlide
                key={subIndex}
                className={`section-OurActivities-spawn-element`}
                onClick={() => handleSlideClick(subItem[lang].button.url.data)}
              >
                <img
                  src={subItem[lang].media.data.path}
                  alt={subItem[lang].title.data}
                />
                <div className={`section-OurActivities-spawn-element-content`}>
                  <div
                    className={`section-OurActivities-spawn-element-content-span`}
                  >
                    <h4>{subItem[lang].title.data}</h4>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-scrollbar"></div>
          </Swiper>
        </>
      ))}

      <div className={`section-OurActivities-header`}>
        <div className={`section-OurActivities-header-midd`}></div>
      </div>
    </section>
  );
});

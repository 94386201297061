import React, { useState, useRef, useContext, useEffect } from "react";
import "./sliderProduction.scss";
import { StoreContext } from "../../store/store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/core";
import "swiper/swiper.min.css";

export const SliderProduction = (props) => {
  const { section } = props;
  const { lang } = useContext(StoreContext);
  const swiperRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const popupRef = useRef(null);
  const overlayRef = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(5);

  const [popupOpen, setPopupOpen] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");

  const openPopup = (video) => {
    setUrlVideo(video);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    const updateSlidesPerView = () => {
      if (window.innerWidth <= 500) {
        setSlidesPerView(1);
      } else if (window.innerWidth <= 750) {
        setSlidesPerView(2);
      } else if (window.innerWidth <= 1072) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(5);
      }
    };

    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);

    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  const handlePopupClick = () => {
    closePopup();
  };

  useEffect(() => {
    if (section.listTeam && section.listTeam.data.length < 13) {
      const numTriplings = 3;

      const tripledArray = [...section.listTeam.data];

      for (let i = 0; i < numTriplings - 1; i++) {
        tripledArray.push(...section.listTeam.data);
      }

      section.listTeam.data = tripledArray;
    }

    if (section.listProduction && section.listProduction.data.length < 13) {
      const numTriplings = 3;

      const tripledArray = [...section.listProduction.data];

      for (let i = 0; i < numTriplings - 1; i++) {
        tripledArray.push(...section.listProduction.data);
      }

      section.listProduction.data = tripledArray;
    }
  });

  const renderSliderHeader = () => {
    return (
      <>
        {section.titleForDev.data === "lastProductionHome" && (
          <div className={`section-lastProductionHome-header`}>
            <h2>{section.title.data}</h2>
          </div>
        )}

        {section.titleForDev.data === "lastProductDiffusion" && (
          <div
            className={`section-lastProductionHome-header ${
              section.titleForDev.data === "lastProductDiffusion" &&
              "lastProductDiffusion"
            }`}
          >
            <h2>{section.title.data}</h2>
          </div>
        )}

        {section.listTeam && (
          <div
            className={`section-lastProductionHome-header ${
              section.listTeam && "comite-executif"
            }`}
          >
            <h2>{section.title.data}</h2>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {renderSliderHeader()}
      <Swiper
        slidesPerView={slidesPerView}
        autoplay={{
          delay: 2500,
          disableOnInteractions: true,
        }}
        loop
        spaceBetween={1.5}
        loopedSlides={7}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        className={`section-SliderProduction ${
          section.titleForDev.data === "lastProductionHome" &&
          `lastProductionHome`
        } ${section.listTeam && "comite-executif"} ${
          section.titleForDev.data === "lastProductDiffusion" &&
          "lastProductDiffusion"
        }`}
        onSlideChange={(swiper) => {
          swiper.autoplay.stop();
        }}
        onTransitionEnd={(swiper) => {
          swiper.autoplay.start();
        }}
        modules={[Autoplay, Navigation]}
        ref={swiperRef}
        slideToClickedSlide={true}
        centeredSlides={true}
        onRealIndexChange={(swiper) => {
          setActiveIndex(swiper.realIndex);
        }}
      >
        {section.listTeam
          ? section.listTeam?.data.map((item, index) => {
              const mediaPath = item[lang].media.data.path;
              const isComiteExecutif = section.listTeam;
              const isActiveIndex = index === activeIndex;
              const hasSpaces = item[lang].title.data.includes(" ");
              const splitTitle = item[lang].title.data.split(" ");
              const firstPart = splitTitle.slice(0, -1).join(" ");
              const secondPart = splitTitle.pop();

              return (
                <SwiperSlide
                  key={index}
                  className={`section-SliderProduction-item`}
                >
                  {mediaPath && (
                    <img
                      src={mediaPath}
                      alt={`Slide ${index}`}
                      loading="lazy"
                    />
                  )}
                  <div
                    className={`section-SliderProduction-item-top ${
                      section.titleForDev.data === "lastProductionHome" &&
                      `lastProductionHome`
                    }  ${section.listTeam && "comite-executif"} `}
                  ></div>
                  <div
                    className={`section-SliderProduction-item-main ${
                      section.titleForDev.data === "lastProductionHome" &&
                      `lastProductionHome`
                    }`}
                  >
                    <div className={`section-SliderProduction-item-main-top`}>
                      <p
                        className={`section-SliderProduction-item-main-top-title ${
                          isComiteExecutif && `comiteexecutif`
                        }`}
                      >
                        {isComiteExecutif ? (
                          <>
                            {isComiteExecutif ? (
                              hasSpaces ? (
                                <span>
                                  {firstPart}
                                  <br />
                                  <span
                                    style={{
                                      marginTop: "2rem",
                                      display: "inline-block",
                                    }}
                                  >
                                    {secondPart}
                                  </span>
                                </span>
                              ) : (
                                item[lang].title.data
                              )
                            ) : (
                              item[lang].title.data
                            )}
                          </>
                        ) : (
                          item[lang].title.data
                        )}
                      </p>
                      {isActiveIndex && (
                        <span
                          className={`section-SliderProduction-item-main-top-span`}
                        />
                      )}
                    </div>
                    <div
                      className={`section-SliderProduction-item-main-bottom`}
                    ></div>
                  </div>
                </SwiperSlide>
              );
            })
          : section.listProduction?.data.map((item, index) => {
              const mediaPath = item[lang].media.data.path;

              return (
                <SwiperSlide
                  key={index}
                  className={`section-SliderProduction-item ${
                    index === activeIndex - 2 ? "noLeftBorder" : ""
                  }  ${index === activeIndex + 2 ? "noRightBorder" : ""}
                  ${index === activeIndex + 1 ? "borderBeforeEnd" : ""}
                  ${index === activeIndex - 1 ? "borderAfterStart" : ""}
                  ${index === activeIndex ? "borderCenter" : ""}`}
                >
                  {mediaPath && (
                    <img
                      src={mediaPath}
                      alt={`Slide ${index}`}
                      loading="lazy"
                    />
                  )}
                  <div
                    className={`section-SliderProduction-item-top ${
                      section.titleForDev.data === "lastProductionHome" &&
                      `lastProductionHome`
                    } ${
                      section.titleForDev.data === "lastProductDiffusion" &&
                      `lastProductDiffusion`
                    } ${
                      section.titleForDev.slug === "comite-executif" &&
                      "comite-executif"
                    }`}
                  ></div>
                  <div
                    className={`section-SliderProduction-item-main ${
                      section.titleForDev.data === "lastProductionHome" &&
                      `lastProductionHome`
                    } ${
                      section.titleForDev.data === "lastProductDiffusion" &&
                      `lastProductDiffusion`
                    }`}
                  >
                    <div className={`section-SliderProduction-item-main-top`}>
                      <p
                        className={`section-SliderProduction-item-main-top-title`}
                      >
                        {item[lang].title.data}
                      </p>
                      {index === activeIndex && (
                        <span
                          className={`section-SliderProduction-item-main-top-span`}
                        />
                      )}

                      {item[lang].video.data && index === activeIndex && (
                        <div
                          className={`section-SliderProduction-item-main-top-lecteur`}
                          onClick={() => openPopup(item[lang].video.data)}
                        >
                          <div>
                            <p>{">"}</p>
                          </div>
                          <p
                            className={`section-SliderProduction-item-main-top-lecteur-title`}
                          >
                            Watch trailler
                          </p>
                        </div>
                      )}
                    </div>
                    <div
                      className={`section-SliderProduction-item-main-bottom`}
                    ></div>
                  </div>
                </SwiperSlide>
              );
            })}

        {section.listTeam && (
          <>
            <div className="swiper-button-next" ref={navigationNextRef}></div>
            <div className="swiper-button-prev" ref={navigationPrevRef}></div>
          </>
        )}
      </Swiper>

      {popupOpen && (
        <div
          className="popup-overlay"
          ref={overlayRef}
          onClick={handlePopupClick}
        >
          <div
            className="popup-watch"
            ref={popupRef}
            onClick={handlePopupClick}
          >
            <iframe
              title="vimeo-player"
              src={`https://player.vimeo.com/video/${urlVideo}`}
              frameBorder="0"
              allowFullScreen
              className="iframe-fullsize"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

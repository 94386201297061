
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { StoreContext } from "../../store/store";

export const Lang = (props) => {
  const { listLang = [] } = props;

  const { pathname, hash } = useLocation();

  const { setLang, lang: currentLang } = useContext(StoreContext);

  const generateLangLink = () => {
    return listLang.map((el, i) => {
      const path = `${pathname.slice(4, pathname.length)}${hash}`;

      return (
        <Link
        key={i}
        to={`/${el}/${path}`} 
        className={`langElem ${currentLang === el ? 'active' : ''}`} 
        onClick={() => setLang(el)}
    >
          <img src={`${el != "fr" ? "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Great_Britain_%28English_version%29.png" : "https://upload.wikimedia.org/wikipedia/commons/3/37/Flag_of_France_%28bordered%29.png"}`} />
          <span className={`${currentLang  !== el ? 'header-langue-none' : ''}`}/>
        </Link>
      );
    });
  };

  return <div className="header-langue">{generateLangLink()}</div>;
};

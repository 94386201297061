import { useEffect, useRef } from "react";
import "./videoIntro.scss";
import videoIntro from "../../utils/JINGLE_NB_6S_07.gif";
import CloseIcon from "@mui/icons-material/Close";

export const VideoIntro = () => {
  const loaderContainer = useRef();

  return (
    <div id="videoLoading" ref={loaderContainer}>
      <img src={videoIntro}  />
    </div>
  );
};

import { useState, useRef, forwardRef, useContext, useEffect } from "react";
import "./theGroup.scss";
import { StoreContext } from "../../store/store";
import { Link } from "react-router-dom";

export const TheGroup = forwardRef((props, ref) => {
  const [content2, setContent2] = useState(false);

  useEffect(() => {
    if (props.idSection == 6) {
      setContent2(true);
    }
  });
  const { section, template, bgColor } = props;
  const { sliderHome = {}, content = {}, button = {} } = section;
  const {
    title: btnTitle = {},
    status: btnStatus = {},
    url: btnUrl = {},
  } = button;
  const { lang } = useContext(StoreContext);

  const phrase = section.title.data;
  const mots = phrase.split(" ");
  const premierH2 = mots.slice(0, mots.length - 1).join(" ");
  const dernierMot = mots[mots.length - 1];


  const motscontent2 = section.title.data.split(" ");
  const elementsAvecRetourALaLigne = motscontent2.map((mot, index) => (
    <h2>{mot}</h2>
  ));

  return (
    <section className={`section-TheGroup ${content2 && "content2"}`}>
      {section.media.data.path && <img src={`${section.media.data.path}`} />}

      <div className={`section-TheGroup-left ${content2 && "content2"}`}>
        <span className={`section-TheGroup-left-span`} />
        <span className={`section-TheGroup-left-span`} />
      </div>

      <div className={`section-TheGroup-midd ${content2 && "content2"}`}>
        <div className={`section-TheGroup-midd-top ${content2 && "content2"}`}>
          <div
            className={`section-TheGroup-right-span-blur  ${
              content2 && "content2"
            }`}
          >
            <h2>{section.title.data}</h2>
          </div>
        </div>

        <div className={`section-TheGroup-midd-midd ${content2 && "content2"}`}>
          <div
            className={`section-TheGroup-right-span-content ${
              content2 && "content2"
            }`}
          >
            {content2 ? (
              <>
                <h2 className={`${content2 && "titleContent2"}`}>
                  {premierH2}
                </h2>
                <h2 className={`${content2 && "titleContent2"}`}>
                  {dernierMot}
                </h2>
              </>
            ) : (
              <>{elementsAvecRetourALaLigne}</>
            )}
          </div>

          <div
            className={`section-TheGroup-right-span-blur  ${
              content2 && "content2"
            }`}
          >
            {content2 ? (
              <>
                <p
                  dangerouslySetInnerHTML={{
                    __html: section.content.data,
                  }}
                ></p>

                {section.listContenu.data.map((item) => (
                  <div>
                    <span>
                      <p>+</p>
                    </span>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: item[lang].contenu.data,
                      }}
                    ></p>
                  </div>
                ))}
              </>
            ) : (
              <p
                className={`section-TheGroup-right-span-blur-title`}
                dangerouslySetInnerHTML={{ __html: section.content.data }}
              />
            )}
          </div>
        </div>

        <div
          className={`section-TheGroup-midd-bottom ${content2 && "content2"}`}
        >
          <div
            className={`section-TheGroup-right-span-blur ${
              content2 && "content2"
            }`}
          >
            {!content2 && (
              <>
                <Link to={`/${lang}/${button.url?.data}`}>
                  <p
                    className={`section-TheGroup-right-span-blur-title`}
                    dangerouslySetInnerHTML={{ __html: button.title?.data }}
                  />
                  <p className="section-TheGroup-right-span-blur-btn">+</p>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={`section-TheGroup-right ${content2 && "content2"}`}>
        <span className={`section-TheGroup-right-span `} />
        <span className={`section-TheGroup-right-span`} />
      </div>
    </section>
  );
});

import { forwardRef, useContext, useState } from "react";
import "./listStudioActivity.scss";
import { StoreContext } from "../../store/store";

export const ListStudioActivity = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;

  const { lang } = useContext(StoreContext);

  return (
    <section className="section-ListStudioActivity">
      {section.listLogo.data.map((item) => (
        <img src={item[lang].media.data.path} />
      ))}
    </section>
  );
});

import { forwardRef } from "react";
import { getCookieAuth } from "../../helpers";

export const VideoFullScreen = forwardRef((props) => {
    const { section, template } = props;
    const { video = {}, type = {} } = section;

    return(
        <section className={`pageSection videoSection ${template}`}>
            {
                type.data === "vimeo" ? (
                    <div style={{ padding: "28%", position: "relative" }}>
                        <iframe
                            title="Vimeo video player"
                            src={`https://player.vimeo.com/video/${video.data}${
                                getCookieAuth("vimeo") ?
                                    (video.data && video.data.includes("?") ? "&" : "?")
                                : 
                                    (video.data && video.data.includes("?") ? "&dnt=1" : "?dnt=1")
                            }`}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%"
                            }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                ) : type.data === "youtube" ? (
                    <div style={{ padding: "28%", position: "relative" }}>
                        <iframe
                            title="YouTube video player"
                            src={`https://www.youtube.com/embed/${video.data}`}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%"
                            }}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </div>
                ) : null
            }
        </section>

    )
})
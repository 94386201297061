import { useContext } from "react";
import "./Footer.scss";
import { StoreContext } from "../../store/store";
import { Link } from "react-router-dom";
import LogoX from "../../utils/img/x.png";
import Face from "../../utils/img/face.png";

export const Footer = () => {
  const { menusData, lang, pageData } = useContext(StoreContext);
  const { sectionPage } = pageData ?? {};
  const { footer: footerData = {} } = menusData ?? {};
  const { info = {}, menuFooterFirst: menu } = footerData;
  const {
    address = {},
    content = {},
    linkedin = {},
    logo_lfa = {},
    logo_superprod = {},
    logo_trusted = {},
    phone = {},
    twitter = {},
    zip = {},
  } = info;
  const { content: footerDesc = {} } = content[lang] ?? {};

  return (
    footerData && (
      <footer>
        <div className="footer-content">
          <Link to={`/${lang}/`}>
            <img src={footerData.info?.logo_mediawan.data.path} alt="logo" />
          </Link>
          <div>
            {footerData.info?.address.data && (
              <p>{footerData.info.address.data}</p>
            )}

            {footerData.info?.zip.data && <p>{footerData.info.zip.data}</p>}
            {footerData.info?.phone.data && <p>{footerData.info.phone.data}</p>}
          </div>
        </div>

        <div className="footer-content">
          <p className="footer-content-title">
            {lang === "fr" ? "Navigation" : "Browsing"}
          </p>
          <div>
            {footerData.menuFooterFirst?.map((item) => (
              <Link to={`/${lang}/${item[lang].slug}`}>
                <p>{item[lang].title}</p>
              </Link>
            ))}
          </div>
        </div>

        <div className="footer-content">
          <p className="footer-content-title">{lang === "fr" ? "Autres" : "Others"}</p>
          <div>
            {footerData.menuFooterTwo?.map((item) => (
                // Vérifie si external est true
                item.externalLink ? (
                  // Si external est true, crée un lien avec target="_blank"
                  <a href={`${item.url}`} target="_blank" rel="noopener noreferrer">
                    <p>{item[lang].title}</p>
                  </a>
                ) : (
                  // Sinon, crée un lien normal
                  <Link to={`/${lang}/${item[lang].slug}`}>
                    <p>{item[lang].title}</p>
                  </Link>
                )
            ))}
          </div>
        </div>

        <div className="footer-content iconList">
          <div className="content-icon">
            <Link
              to={footerData.info?.instagram.data.replace(/<\/?p>/g, "")}
              target="_blank"
            >
              <img src="https://www.pngmart.com/files/22/Black-And-White-Instagram-Logo-PNG-File.png" />
            </Link>
            <Link to={`${footerData.info?.linkedin.data}`} target="_blank">
              <img src="https://www.pngall.com/wp-content/uploads/2016/07/Linkedin-PNG.png" />
            </Link>
            <Link to={`${footerData.info?.facebook?.data}`} target="_blank">
              <img src={Face} />
            </Link>
            <Link
              to={footerData.info?.twitter.data.replace(/<\/?p>/g, "")}
              target="_blank"
            >
              <img src={LogoX} />
            </Link>
          </div>
        </div>
      </footer>
    )
  );
};

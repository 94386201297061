import { useState, useRef, forwardRef, useContext } from "react";
import "./whyJoinUs.scss";
import { StoreContext } from "../../store/store";
import { Link } from "react-router-dom";
export const WhyJoinUs = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;

  const { lang } = useContext(StoreContext);

  const backgroundStyle = {
    backgroundImage: `url('${section.media.data.path}')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  function telechargerPDF() {
    // Sélectionnez le lien caché
    const lienDeTelechargement = document.getElementById(
      "lien-de-telechargement"
    );

    // Cliquez sur le lien caché pour déclencher le téléchargement
    if (lienDeTelechargement) {
      lienDeTelechargement.click();
    }
  }

  console.log(section.button)

  return (
    <section className="section-WhyJoinUs" style={backgroundStyle}>
      <div className="section-WhyJoinUs-content">
        <div className="section-WhyJoinUs-left">
          <h1>{section.title.data}</h1>
        </div>
        <div className="section-WhyJoinUs-right">
          <div className="section-WhyJoinUs-right-top">
            <p dangerouslySetInnerHTML={{ __html: section.content.data }}></p>
          </div>
          {/*}
          <div className="section-WhyJoinUs-right-bottom">
            {section.button?.status.data == true && (
              <div
                className={`section-WhyJoinUs-right-bottom-left`}
                onClick={telechargerPDF}
              >
                  <p>TELECHARGER NOTRE CODE CONDUITE</p>
                  <span>+</span>
                  <a href={section.button.url.data} id="lien-de-telechargement" style={{ display: "none" }} target="_blank">Télécharger</a>
              </div>
            )}

            <div className={`section-WhyJoinUs-right-bottom-right ${section.button?.status.data == false &&  "justeOne"}`}>
              <a href="https://mediawan.integrityline.org/" target="_blank">
                <p>ACCEDER A NOTRE PLATFORME D'ALERTE</p>
              </a>
            </div>
          </div>
          */}
        </div>
      </div>
    </section>
  );
});

import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Pagination } from "swiper";
import { Autoplay } from "swiper/core";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./fondateur.scss";
import { StoreContext } from "../../store/store";

export const Fondateur = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;

  const { lang } = useContext(StoreContext);

  const [selectedFondateurIndex, setSelectedFondateurIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [popupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setPopupOpen(false);
      }
    };

    if (popupOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [popupOpen]);

  const openPopup = () => {
    setSelectedIndex(selectedFondateurIndex);

    setPopupOpen(true);
  };

  return (
    <section className="section-fondateur">
      <div className="section-fondateur-top">
        <h3>{section.title.data}</h3>
      </div>
      <div className="section-fondateur-midd">
        <Swiper
          spaceBetween={20}
          loop
          autoplay={{
            delay: 2500,
            disableOnInteractions: true,
          }}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          onSlideChange={(swiper) => {
            setSelectedFondateurIndex(swiper.realIndex);

          }}
          className="section-fondateur-midd-caroussel"
          style={{
            "--swiper-pagination-color": "#BCAF95",
            "--swiper-pagination-bullet-inactive-color": "none",
            "--swiper-pagination-bullet-inactive-border": "1px red solid",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "13px",
          }}
        >
          {section.fondateurItem.data.map((fondateur, index) => (
            <SwiperSlide key={index}>
              <img
                src={`${fondateur[lang].media.data.path}`}
                alt={"fondateur"}
                onClick={openPopup}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="section-fondateur-midd-right">
          <h1>
            {
              section.fondateurItem.data[selectedFondateurIndex][lang].title
                .data
            }
          </h1>
          <h2>
            {section.fondateurItem.data[selectedFondateurIndex][lang].post.data}
          </h2>
          <p
            dangerouslySetInnerHTML={{
              __html:
                section.fondateurItem.data[selectedFondateurIndex][lang]
                  .contentInfo?.data,
            }}
          ></p>

          <div className="section-fondateur-midd-right-btn">
            <p onClick={openPopup}>Lire la suite</p>
            <span onClick={openPopup}>+</span>
          </div>
          {popupOpen && (
            <div className="popup" ref={popupRef}>
              <div className="popup-left">
                <span />
                <span />
              </div>
              <div className="popup-midd">
                <div className="popup-midd-top"></div>
                <div className="popup-midd-midd">
                  <h1>
                    {
                      section.fondateurItem.data[selectedIndex][lang]
                        .title.data
                    }
                  </h1>
                  <h2>
                    {
                      section.fondateurItem.data[selectedIndex][lang]
                        .post.data
                    }
                  </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        section.fondateurItem.data[selectedIndex][lang]
                          .contenu.data,
                    }}
                  ></p>
                </div>
                <div className="popup-midd-bottom"></div>
              </div>
              <div className="popup-right">
                <span />
                <span />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="section-fondateur-bottom"></div>
    </section>
  );
});

import { forwardRef, useEffect, useState, useContext, useRef } from "react";
import "./howApply.scss";
import { StoreContext } from "../../store/store";

export const HowApply = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const contentRef = useRef(null);
  const { lang } = useContext(StoreContext);

  useEffect(() => {
    if (contentRef.current) {
      // Copiez le contenu HTML de section.content.data
      const contentHtml = section.content.data;

      // Utilisez une expression régulière pour ajouter target="_blank" aux balises a
      const sanitizedHtml = contentHtml.replace(/<a/g, '<a target="_blank"');

      // Mettez à jour le contenu de la balise p avec le contenu modifié
      contentRef.current.innerHTML = sanitizedHtml;
    }
  }, [section.content.data]);

  return (
    <section className={`section-HowApply`}>
      <div className="section-HowApply-contentImage">
        <img src={section.media.data.path} alt="actor"/>
      </div>
      <main>
        <article>
          <h3>{section.title.data}</h3>
          <p  ref={contentRef} dangerouslySetInnerHTML={{ __html: section.content.data }}></p>
        </article>
      </main>
    </section>
  );
});

import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./textInfoActivity.scss";
import { StoreContext } from "../../store/store";

export const TextInfoActivity = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;

  const { lang } = useContext(StoreContext);
  const contentRef = useRef(null);
  const content2Ref = useRef(null);


  useEffect(() => {
    if (contentRef.current && section.content) {
      // Copiez le contenu HTML de section.content.data
      const contentHtml = section.content.data;

      // Utilisez une expression régulière pour ajouter target="_blank" aux balises a
      const sanitizedHtml = contentHtml.replace(/<a/g, '<a target="_blank"');

      // Mettez à jour le contenu de la balise p avec le contenu modifié
      contentRef.current.innerHTML = sanitizedHtml;
    }
  }, [section.content?.data]);

  
  useEffect(() => {
    if (content2Ref.current && section.contenu2) {
      // Copiez le contenu HTML de section.content.data
      const contentHtml = section.contenu2.data;

      // Utilisez une expression régulière pour ajouter target="_blank" aux balises a
      const sanitizedHtml = contentHtml.replace(/<a/g, '<a target="_blank"');

      // Mettez à jour le contenu de la balise p avec le contenu modifié
      content2Ref.current.innerHTML = sanitizedHtml;
    }
  }, [section.contenu2?.data]);


  return (
    <section className="section-TextInfoActivity">
      <div className="section-TextInfoActivity-left"></div>
      <div className="section-TextInfoActivity-midd" >
        <div className="section-TextInfoActivity-midd-left">
          <p ref={contentRef} dangerouslySetInnerHTML={{ __html: section.contenu?.data }}></p>
        </div>

        <div className="section-TextInfoActivity-midd-right">
          <p ref={content2Ref} dangerouslySetInnerHTML={{ __html: section.contenu2?.data }}></p>
        </div>
      </div>
      <div className="section-TextInfoActivity-right"></div>
    </section>
  );
});

import { forwardRef, useContext, useEffect, useState } from "react";
import "./keyNumber.scss";
import { StoreContext } from "../../store/store";

export const KeyNumber = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { lang } = useContext(StoreContext);

  // Créez un objet pour stocker les données de chiffres
  const initialData = {
    labels: 0,
    programs: 0,
    channels: 0,
    collaborators: 0,
    countries: 0,
    revenue: 0,
  };

  // Utilisez l'état pour gérer les données de chiffres
  const [data, setData] = useState(initialData);

  // Fonction pour animer les chiffres
  const animateNumbers = () => {
    const newData = {
      labels: 60,
      programs: 2000,
      channels: 17,
      collaborators: 1500,
      countries: 11,
      revenue: 1000000000,
    };

    const duration = 2000; // Durée de l'animation en millisecondes
    const interval = 50; // Intervalle de mise à jour en millisecondes
    const steps = duration / interval; // Nombre d'étapes

    Object.keys(newData).forEach((key) => {
      const step = Math.ceil(newData[key] / steps); // Valeur à ajouter à chaque étape
      let current = 0;

      const intervalId = setInterval(() => {
        current += step;
        if (current >= newData[key]) {
          clearInterval(intervalId);
          current = newData[key];
        }

        setData((prevData) => ({
          ...prevData,
          [key]: current,
        }));
      }, interval);
    });
  };

  // Démarrez l'animation après un délai
  useEffect(() => {
    setTimeout(animateNumbers, 500);
  }, []);

  return (
    <section className="section-KeyNumber">
      <header>
        <h3>{section.title.data}</h3>
      </header>
      <main className="grid-container">
        {section.numberItem.data.map((item, index) => (
          <div className="grid-item">
            <span>{item[lang].title.data}</span>
            <p className="grid-item-text" dangerouslySetInnerHTML={{ __html: item[lang].contenu.data }}></p>
          </div>
        ))}
      </main>
    </section>
  );
});

import { useEffect, useCallback, useContext, Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./SectionManager.scss";
import { getPageData } from "../../store/index.service";
import { StoreContext } from "../../store/store";
import { Helmet } from "react-helmet";

//Importation des sections
import { Fondateur } from "../../sections/fondateur/Fondateur";
import { HowApply } from "../../sections/howApply/HowApply";
import { Investisseur } from "../../sections/investisseur/Investisseur";
import { KeyNumber } from "../../sections/keyNumber/KeyNumber";
import { LegalMention } from "../../sections/legalMention/LegalMention";
import { OurActivities } from "../../sections/ourActivities/OurActivities";
import { OurCulture } from "../../sections/ourCulture/OurCulture";
import { TheGroup } from "../../sections/theGroup/TheGroup";
import { VideoInfo } from "../../sections/videoInfo/VideoInfo";
import { WhyJoinUs } from "../../sections/whyJoinUs/WhyJoinUs";
import { SliderProduction } from "../../sections/sliderProduction/SliderProduction";
import { ListStudioActivity } from "../../sections/listStudioActivity/ListStudioActivity";
import { TextInfoActivity } from "../../sections/textInfoActivity/TextInfoActivity";
import { SliderActivity } from "../../sections/sliderActivity/SliderActivity";
import { VideoFullScreen } from "../../sections/videoFullScreen/VideoFullScreen";

export const SectionManager = () => {
	const location = useLocation();
	const { locale } = useParams();
	const { setPageData, pageData, lang, setLang } = useContext(StoreContext);
	const { page = {}, sectionPage, listActu } = pageData ?? {};
	const { metaTitle = {}, metaContent = {} } = page[lang] ?? {};

	const ref = useCallback((node) => {
		if (node !== null && node !== undefined) {
			window.scrollTo({
				top: node.offsetTop,
				left: 0,
				behavior: "smooth",
			});
		}
	}, []);

	useEffect(() => {
		getPageData(location.pathname)
		.then((res) => {
			setPageData({
				page: res.data.infoPage ?? {},
				sectionPage: res.data.page ?? [],
			});
		})
		.catch((err) => {
			console.error(err);
			setPageData({
				page: { slug: "not-found" },
				sectionPage: [{ template: "notFound" }],
			});
		});
	}, [location.pathname]);

	useEffect(() => {
		setLang(locale);
	}, [locale]);

	const generateSection = (section = {}, i) => {
		switch (section.template) {
			case "fondateur":
				return (
					<Fondateur
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "howApply":
				return (
					<HowApply
						section={section[lang]}
						metiers={section.entity}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "investisseur":
				return (
					<Investisseur
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "sliderActivity":
				return (
					<SliderActivity
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "listStudioActivity":
				return (
					<ListStudioActivity
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "textInfoActivity":
				return (
					<TextInfoActivity
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "sliderProduction":
				return (
					<SliderProduction
						section={section[lang]}
						projets={section.projet}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "keyNumber":
				return (
					<KeyNumber
						section={section[lang]}
						projets={section.projet}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "legalMention":
				return (
					<LegalMention
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "videoFullscreen":
				return (
					<VideoFullScreen
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "ourActivities":
				return (
					<OurActivities
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "ourCulture":
				return (
					<OurCulture
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "theGroup":
				return (
					<TheGroup
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "videoInfo":
				return (
					<VideoInfo
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
					/>
				);
			case "whyJoinUs":
				return (
					<WhyJoinUs
						section={section[lang]}
						bgColor={section.bgColor}
						template={section.template}
						idSection={section.idSection}
						key={i}
						ref={location.hash === `#${section.subTitle}` ? ref : undefined}
						isLink
					/>
				);
		}
	};

	return (
		<>
			<Helmet htmlAttributes={{ lang: lang }}>
				<title>{metaTitle.data ?? "Superprod"}</title>
				<meta name="description" content={metaContent.data} />
			</Helmet>

			{page && (
				<div id={page.slug}>
				{sectionPage &&
					sectionPage.map((section, i) =>
					/*section.status &&*/ generateSection(section, i)
					)}
				</div>
			)}
		</>
	);
};

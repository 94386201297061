import { useState, useRef, forwardRef, useContext } from "react";
import { Link } from "react-router-dom";
import "./videoInfo.scss";
import { Button } from "../../components/UI/Button/Button";
import { StoreContext } from "../../store/store";
import { html } from "../../helpers";
import { FadeSection } from "../../components/FadeSection/FadeSection";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, Controller } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import arrowRight from "../../utils/img/arrow-right.svg";

export const VideoInfo = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { sliderHome = {}, content = {}, button = {} } = section;
  const {
    title: btnTitle = {},
    status: btnStatus = {},
    url: btnUrl = {},
  } = button;
  const { lang } = useContext(StoreContext);

  const mots = section.title.data.split(" ");
  const elementsAvecRetourALaLigne = mots.map((mot, index) => (
    <h2>
      {mot}
      <br />
    </h2>
  ));
  const [videoStart, setVideoStart] = useState(false)
  return (
    <section className={`section-VideoInfo`}>
      <div className={`section-VideoInfo-left`}>
        <span className={`section-VideoInfo-left-span`} />
        <span className={`section-VideoInfo-left-span`} />
      </div>
      <div className={`section-VideoInfo-midd`}>
        <div className={`section-VideoInfo-midd-top`}>
          <div className={`section-VideoInfo-right-span-blur`}></div>
        </div>
        <div className={`section-VideoInfo-midd-midd`}>
          <div className={`section-VideoInfo-right-span-content`}>
            {videoStart ? (
              <iframe
                title="vimeo-player"
                src={`https://player.vimeo.com/video/${section.video.data}`}
                width="500"
                height="500"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            ) : (
              <>
                <img src={section.media.data.path} />
                <span
                  className={`section-VideoInfo-right-span-content-span-top`}
                />
                <span
                  className={`section-VideoInfo-right-span-content-span-left`}
                />
                <span
                  className={`section-VideoInfo-right-span-content-span-right`}
                />
                <span
                  className={`section-VideoInfo-right-span-content-span-bottom`}
                />

                <span
                  className={`section-VideoInfo-right-span-content-span-rec-top-left`}
                />
                <span
                  className={`section-VideoInfo-right-span-content-span-rec-top-right`}
                />

                <span
                  className={`section-VideoInfo-right-span-content-span-rec-bottom-left`}
                />
                <span
                  className={`section-VideoInfo-right-span-content-span-rec-bottom-right`}
                />

                <span
                  className={`section-VideoInfo-right-span-content-span-rec-lecteur`}
                  onClick={() => setVideoStart(true)}
                >
                  <p>{">"}</p>
                </span>
              </>
            )}
          </div>

          <div className={`section-VideoInfo-right-span-blur`}>
            <div className={`section-VideoInfo-right-span-blur-top`}>
              <h3>{section.title.data}</h3>
            </div>
            <div className={`section-VideoInfo-right-span-blur-bottom`}>
              <p dangerouslySetInnerHTML={{ __html: section.content.data }}></p>
            </div>
          </div>
        </div>
        <div className={`section-VideoInfo-midd-bottom`}>
          <div className={`section-VideoInfo-right-span-blur`}></div>
        </div>
      </div>
      <div className={`section-VideoInfo-right`}>
        <span className={`section-VideoInfo-right-span`} />
        <span className={`section-VideoInfo-right-span`} />
      </div>
    </section>
  );
});

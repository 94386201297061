import { forwardRef, useContext, useState } from "react";
import "./ourCulture.scss";
import { StoreContext } from "../../store/store";

export const OurCulture = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;

  const { lang } = useContext(StoreContext);

  return (
    <section className="section-OurCulture">
      <header className="section-OurCulture-header">
        <h3>{section.title.data}</h3>
      </header>
      <main className="grid-container">
        {section.cultureItem.data.map((e) => (
          <div className="grid-item">
            <div className="grid-item-content">
              <div>
                <p>{e[lang].number.data}</p>
              </div>
              <div>
                <span>{e[lang].title.data}</span>
                <span
                  dangerouslySetInnerHTML={{ __html: e[lang].contenu.data }}
                ></span>
              </div>
            </div>
          </div>
        ))}
      </main>
    </section>
  );
});

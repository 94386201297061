import { forwardRef, useContext, useEffect, useState } from "react";
import "./legalMention.scss";
import { StoreContext } from "../../store/store";

export const LegalMention = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;

  const { lang } = useContext(StoreContext);
  return (
    <section className="section-LegalMention">
      <h2>{section.title.data}</h2>
      <p
        dangerouslySetInnerHTML={{
          __html: section.contenu.data,
        }}
      ></p>
    </section>
  );
});

import { forwardRef, useContext, useState } from "react";
import "./sliderActivity.scss";
import { StoreContext } from "../../store/store";

export const SliderActivity = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;

  const { lang } = useContext(StoreContext);

  const words = section.listTeam.data[0][lang].title.data.split(" ");
  const elementsAvecRetourALaLigne = [];
  let currentH2Content = ""; // Initialisez une chaîne pour stocker le contenu de la balise h2 en cours

  // Parcourez les mots et construisez le contenu de h2 en cours
  for (let i = 0; i < words.length; i++) {
    if (currentH2Content !== "") {
      // Ajoutez un espace si ce n'est pas le premier mot dans h2
      currentH2Content += " ";
    }
    currentH2Content += words[i];

    // Si le mot suivant est un espace ou si nous sommes à la fin des mots
    if (i === words.length - 1 || words[i + 1] === "") {
      // Ajoutez le contenu de h2 en cours à la liste des éléments
      elementsAvecRetourALaLigne.push(
        <h2 key={i}>{currentH2Content}</h2>
      );
      // Réinitialisez le contenu de h2 en cours
      currentH2Content = "";
    }
  }
  
  
  return (
    <section className="section-SliderActivity">
      <img src={section.listTeam.data[0][lang].media.data.path} />
      <div className="section-SliderActivity-left">
        <span />
        <span />
      </div>

      <div className="section-SliderActivity-midd">
        <div className="section-SliderActivity-midd-left">
          <span />
          <span />
          <span />
        </div>
        <div className="section-SliderActivity-midd-right">
          <span />
          <span>
           <h2>{section.listTeam.data[0][lang].title.data}</h2>
            {/*<p>+</p>*/}
          </span>
          <span />
        </div>
      </div>

      <div className="section-SliderActivity-right">
        <span />
        <span />
      </div>
    </section>
  );
});

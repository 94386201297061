import { useState, useContext, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.scss";
import { StoreContext } from "../../store/store";
import { motion, AnimatePresence } from "framer-motion";
import { Lang } from "./Lang";

export const Header = () => {
  const { pathname } = useLocation();

  const { menusData, pageData, lang } = useContext(StoreContext);
  const { page = {} } = pageData ?? {};
  const { header: headerData = {} } = menusData ?? {};
  const { info = {}, menu = [], langue = {} } = headerData;

  const generateLangList = () => {
    let list = [];

    for (const [key, value] of Object.entries(langue)) {
      if (value.data) list.push(key);
    }

    return list;
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleBurger = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = (title) => {
    // Fermer le motion.div
    setIsOpen(false);
    
    // Rediriger l'utilisateur en changeant l'URL
    window.location.href = `/${lang}/${title}`;
  };


  return (
    headerData && (
      <>
        <header className="header-content">
          <div className="header-burger" onClick={handleBurger}>
            <span className={`header-burger-bar ${isOpen ? "remove" : ""}`} />
            <span className={`header-burger-bar ${isOpen ? "remove" : ""}`} />
            <span className={`header-burger-bar ${isOpen ? "remove" : ""}`} />
          </div>

          <Link to={`/${lang}/`}><img src={headerData.info?.image.data.path} alt="logo" /></Link>

          <Lang listLang={generateLangList()} />
        </header>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="header-top-left-content"
              initial={{ transform: "translateX(-100%)" }}
              animate={{ transform: " translateX(0)" }}
              exit={{ transform: " translateX(-100%)" }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              onExitComplete={handleBurger}
            >
              <div className="header-top-left-content-header">
                <div
                  className="header-burger"
                  onClick={() => {
                    // Animation de fermeture
                    setIsOpen(false);
                  }}
                >
                  <span
                    className={`header-burger-bar ${
                      isOpen ? "rotate-down" : ""
                    }`}
                  />
                  <span
                    className={`header-burger-bar ${isOpen ? "open" : ""}`}
                  />
                  <span
                    className={`header-burger-bar ${
                      isOpen ? "rotate-up" : ""
                    }`}
                  />
                </div>
              </div>

              <div className="header-top-left-content-main">
                {headerData.menu.map((e) => (
                  <div key={e.id}>
                    
                    <p onClick={() => handleMenuItemClick(e[lang].slug)}>
                      {e[lang].title}
                    </p>
                    <div className="underline"></div>
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    )
  );
};
